<template>
 <v-container
    class="app-dashboard"
    fluid
    tag="section"
  >
    <v-alert 
      style="grid-area: verifyEmail"
      type="warning"
      dense
      border="left"
      v-if="auth && auth.email && !auth.emailVerified"
      >
      <v-row align="center">
        <v-col class="grow">
          Your email is not verified. Verify to be able to continue using {{ $appName }}.
        </v-col>
        <v-col class="shrink">
          <v-btn
            @click="resend_email"
            v-if="!resent_email"
            :loading="resending_email"
            >
            Resend email
          </v-btn>
          <v-chip v-else>
            Verification email resent
          </v-chip>
        </v-col>
      </v-row>
    </v-alert>
    <PrintServerAlert />
    <template v-for="(store, index) in company.data_sources">
    <v-alert
      :key="index"
      v-if="!store.shopify_scopes_last_updated_at || new Date(store.shopify_scopes_last_updated_at).getTime() < new Date(shopify_scopes_last_updated_at).getTime()"
      type="warning"
      dense>
      <v-col class="grow">
          {{  store.name }} need to update permissions with Shopify to continue work as expected.
        </v-col>
        <v-col class="shrink">
          <v-btn
            @click="update_permissions({store, index})"
            :loading="updating_permissions"
            >
            Update permissions
          </v-btn>
        </v-col>
    </v-alert>
  </template>
    <v-row>
      <v-col cols="12" v-if="get_onboarding_tasks.some(t => !t.done) && user.authenticator === 'shopify'">
        <OnboardingCard />
      </v-col>
      <v-col cols="12" md="6" v-if="!company.id">
        <v-card 
          class="dashboard_card"
          >
          <v-card-title>
              You have no warehouse or store connections
          </v-card-title>
          <v-card-text>
            Connect a Shopify Store through Shopify App Store and add it to your account to get started.
          </v-card-text>
        </v-card>
      </v-col>
      <template v-else-if="get_user_scope('settings.stores')">
        <v-col cols="12" md="6" v-for="ds in company.data_sources.filter(ds => !ds.location_id)" :key="ds.id">
        <v-card
          class="dashboard_card"
          outlined
          >
          <v-card-title>Which fulfillment location do you wish to use for {{ ds.name }}?</v-card-title>
          <FulfillmentLocationSelect 
            :store="ds"
            />
          </v-card>

        </v-col>
        <!-- <v-col cols="12" md="6" v-for="ds in company.data_sources.filter(ds => ds.location_id && ds.uses_shopify_labels && !ds.approved_shopify_beta)" :key="ds.id">
        <v-card
          class="dashboard_card"
          outlined
          >
          <v-card-title>Shopify Labels Beta request under review</v-card-title>
          <v-card-text>We have submitted a request with Shopify to open up {{ ds.name }} for Shopify Labels Beta. We will let you know once approved.</v-card-text>
          </v-card>
        </v-col> -->
      </template>
      <v-col cols="12" md="6" v-if="get_user_scope('warehouse')">
        <v-card
          class="dashboard_card"
          outlined
          >
          <template v-if="loading_products">
            <v-card-title>Products are still syncing</v-card-title>
            <v-card-text>
              <v-progress-circular indeterminate size="24" color="primary"></v-progress-circular>
            </v-card-text>
          </template>
          <template v-else-if="get_amount_unmapped_products">
            <v-card-title>You have {{ get_amount_unmapped_products }} unmapped products</v-card-title>
            <v-card-text>Map them out now to make the fulfillment process more secure and efficient.</v-card-text>
            <v-card-actions>
              <v-btn @click="$router.push('/auth/warehouse')" color="secondary">Warehouse layout</v-btn>
            </v-card-actions>
          </template>
          <template v-else>
            <v-card-title>All your products are mapped out!</v-card-title>
            <v-card-text>Good job! Make sure to keep the warehouse layout correct.</v-card-text>
          </template>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="get_user_scope('fulfill') && order.order_count">
        <v-card
          class="dashboard_card"
          style="grid-area: order_count"
          >
          <v-card-title>{{ order.order_count }} orders to fulfill</v-card-title>
          <v-card-text>Get going right away!</v-card-text>
          <v-card-actions>
              <v-btn @click="$router.push('/auth/fulfill')" color="secondary">Fulfill orders</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="get_user_scope('logs') && blocked_items.length">
        <v-card
          class="dashboard_card"
          style="grid-area: order_count"
          >
          <v-card-title>{{ blocked_items.length }} items are blocked</v-card-title>
          <v-card-text>
                <v-list-item two-line v-for="(item, index) in blocked_items.slice(0, 3)" :key="`list-${index}`" class="pl-0">
                <v-list-item-content>
                  <v-list-item-title>{{ get_product(item.item_uuid) && get_product(item.item_uuid).title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.blocked_at | DateFilter }}: {{ item.reason }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
          </v-card-text>
          <v-card-actions>
              <v-btn @click="$router.push('/auth/logs')" color="secondary">Review all blocks</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>  
      <v-col cols="12" md="6" v-if="get_user_scope('logs') && blocked_orders.length">
        <v-card
          class="dashboard_card"
          style="grid-area: order_count"
          >
          <v-card-title>{{ blocked_orders.length }} orders are blocked</v-card-title>
          <v-card-text>
                <v-list-item two-line v-for="(order, index) in blocked_orders.slice(0, 3)" :key="`orderlist-${index}`" class="pl-0">
                <v-list-item-content>
                  <v-list-item-title>{{ order.header }}</v-list-item-title>
                  <v-list-item-subtitle>{{ order.blocked_at | DateFilter }}: {{ order.reason }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
          </v-card-text>
          <v-card-actions>
              <v-btn @click="$router.push('/auth/logs')" color="secondary">Review all blocks</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="company.custom_scripts.indexOf('avatar') !== -1">
        <AvatarCertificatesCard
          class="dashboard_card"
          style="grid-area: avatar"
          />
      </v-col>
    </v-row>
 </v-container>

</template>

<script>

import { mapState, mapGetters } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { uid } from 'uid'
import { DateFilter } from '@/utils/filters'
import AvatarCertificatesCard from '@/components/custom/AvatarCertificatesCard'
import PrintServerAlert from '@/components/PrintServerAlert'
import FulfillmentLocationSelect from '@/components/FulfillmentLocationSelect'
import OnboardingCard from '@/components/OnboardingCard'

export default {
  components: {
    AvatarCertificatesCard,
    PrintServerAlert,
    FulfillmentLocationSelect,
    OnboardingCard,
  },
  computed: {
    ...mapGetters({
      get_user_scope: 'user/get_user_scope',
      get_amount_unmapped_products: 'warehouse/get_amount_unmapped_products',
      get_product: 'product/get_product',
      get_onboarding_tasks: 'app/get_onboarding_tasks',
    }),
    ...mapState({
      auth: state=> state.user.user,
      user: state => state.user.userProfile,
      company: state => state.warehouse.company,
      order: state => state.order,
      loading_products: state => state.product.loading_products,
      shopify_scopes_last_updated_at: s => s.app.config.shopify_scopes_last_updated_at
      // custom_scripts: state => state.custom_scripts,
    }),
    blocked_items(){
      return this.company.data_sources.reduce((tot, ds) => !ds.blocked_items ? tot : ds.blocked_items.concat(tot), []).sort((a, b) => new Date(b.blocked_at).getTime() - new Date(a.blocked_at).getTime())
    },
    blocked_orders(){
      return this.company.data_sources.reduce((tot, ds) => !ds.blocked_orders ? tot : ds.blocked_orders.concat(tot), []).sort((a, b) => new Date(b.blocked_at).getTime() - new Date(a.blocked_at).getTime())
    },
    // is_onboarded(){
    //   return this.onboarding_tasks.some(t => !t.done)
    // }
  },
  filters: {
    // CurrencyFilter,
    DateFilter,
  },
  data() {
    return {
      resent_email: false,
      resending_email: false,
      updating_permissions: false,
      updated: [],
      dialog: false,
    }
  },
  methods: {
    async update_permissions({store, index}){
      this.updating_permissions = true
      // await this.$store.dispatch('warehouse/update_permissions')
      const state = uid()
      firebase.firestore().collection('data_sources').doc(store.id).set({ shopify_last_known_state_uid: state }, {merge: true })
      const url = `https://${store.myshopify_name}/admin/oauth/authorize?client_id=${process.env.VUE_APP_SHOPIFY_CLIENT_ID}&scope=${process.env.VUE_APP_SHOPIFY_SCOPES}&redirect_uri=${window.location.origin}/&state=${state}`

      window.open(url, '_top')
      this.updating_permissions = false
      this.updated.push(index)
    },
    async resend_email(){
      this.resending_email = true
      await firebase.auth().currentUser.sendEmailVerification()
      this.resent_email = true
      this.resending_email = false
    }

  },
  mounted(){
    
  },

  
}
</script>
<style lang="scss" >
.app-dashboard {
    padding: .5rem 1.25rem;
    // display: grid;
    // grid-gap: 1rem;
    // grid-template-columns: 1fr;
    // grid-template-areas:
    // "verifyEmail"
    // "products"
    // "order_count"
    // "avatar";
    
    // @include sm {
    //   padding: .5rem 1rem;
    // }
    // @include md {
    //   padding: .5rem 1.25rem;
    //   grid-gap: 1.6rem;
    //   grid-template-columns: repeat(2, 1fr);
    //   grid-template-areas:
    //   "verifyEmail verifyEmail"
    //   "products order_count"
    //   "avatar avatar";
    // }
    // @include lg {
    //   padding: .5rem 1.5rem;
    //   grid-gap: 1.6rem;
    //   // grid-template-columns: repeat(auto-fill, 1fr);
    //   grid-template-areas:
    //   "verifyEmail verifyEmail"
    //   "products order_count"
    //   "avatar avatar";
    // }
    // @include xl {
    //   padding: .75rem 1.75rem;
    //   grid-gap: 1.9rem;
    //   grid-template-columns: repeat(12, 1fr);
    //   grid-template-areas:
    //   "verificationAlert verificationAlert verificationAlert verificationAlert verificationAlert verificationAlert verificationAlert verificationAlert verificationAlert verificationAlert verificationAlert verificationAlert"
    //   "summary summary summary summary members members members members verification verification verification verification"
    //   "money money money money money money money info info info info info"
    //   "app app app app app app app app app app app app";
    // }

    // & > .column {
    //   background:#fff;      
    //   border: 1px solid #ffffff;
    //   border-radius: 15px;
    //   box-shadow: 0 2px 17px rgb(0 0 0 / 10%);
    //   transition: all .25s ease;
    //   padding: 2.2rem 2rem;

    //   &:hover {
    //     box-shadow: 0 1px 22px rgb(0 0 0 / 15%);
    //     border-color: #dddddd;
    //   }
    //   @include sm {
    //     border-radius: 25px;
    //     padding: 3rem 3.5rem;
    //   }
    //   @include xl {
    //     padding: 2.5rem 3rem;
    //   }     
    //   @include xxl {
    //     padding: 3rem 3.5rem;
    //   }

    // }
    .dashboard_card {
    // margin: 20px;
    box-shadow: none !important;
    border: thin solid rgba(0, 0, 0, 0.12) !important;
    transition: all .25s ease;
    padding: 1rem 0.8rem;
    // padding: 20px;
      @include sm {
        // border-radius: 25px;
        padding: 2em 0.8rem;
      }
      @include md {
        padding: 1rem 0.8rem;
        
      }     
      @include xxl {
        padding: 1rem 0.8rem;
      }
     .v-btn {
       width: 100%;
       @include sm {
         width: inherit
       }
      background-color: primary !important;
    }
  }
}
  
</style>