<template>
 <v-container
    style="justify-content:start"
  >
  <v-dialog
        v-model="dialog"
        width="500"
        >
        <v-card>
          
            <v-card-title class="text-h5 grey lighten-2">
              Select fulfillment location
            </v-card-title>
            <v-card-text 
              class="mt-4"
              >
              <div v-for="(ds, i) in data_sources" :key="`dschoose-${i}`">
                <h4>Which fulfillment location do you wish to use for {{  ds.name }}</h4>
                <FulfillmentLocationSelect 
                  :store="ds"
                  />
                  <v-divider></v-divider>
              </div>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
  <div class="text-overline">
    Manage warehouse, shipping, staff and highly efficient picking.
  </div>
  <div class="text-h3">
    Your personal Fulfillment Center
  </div>
  <v-divider></v-divider>
  <v-timeline dense>
    <v-timeline-item
      v-for="(task, index) in onboarding_tasks"
      :key="`task-${index}`"
      :color="task.done ? 'green' : task.current ? 'blue' : 'grey'"
      size="x-small">
        <div 
          @click="task.current && !task.done ? handle_button(task.button_action) : null"
          :style="{'cursor': task.current && !task.done ? 'pointer' : 'auto'}"
          class="d-flex"
          >
          <div class="">
            <strong>{{ task.header }}</strong>
            <div class="text-caption">
              {{ task.description }}
            </div>
          </div>
          <div class="flex-shrink pl-10">
            <v-btn 
              v-if="task.current && !task.done"
              color="primary" 
              rounded
              @click.stop="handle_button(task.button_action)"
              >
              {{  task.button_text }}
            </v-btn>
          </div>
        </div>

    </v-timeline-item>
  </v-timeline>
 </v-container>

</template>

<script>

import { mapGetters, mapState } from 'vuex'
import FulfillmentLocationSelect from '@/components/FulfillmentLocationSelect'

export default {
  components: {
    FulfillmentLocationSelect
  },
  computed: {
    ...mapGetters({
      onboarding_tasks: 'app/get_onboarding_tasks',
    }),
    ...mapState({
      data_sources: state => state.warehouse.company.data_sources
    })
  },
  filters: {
    
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    handle_button(action){
      if(action === 'carrier') this.$router.push('/auth/settings/carriers?showDialog=true')
      else if(action === 'shipping') this.$router.push('/auth/settings/stores?showDialog=true')
      else if(action === 'plans') this.$router.push('/auth/plans')
      else if(action === 'warehouse') this.$router.push('/auth/warehouse')
      else if(action === 'printers') this.$router.push('/auth/settings/printers')
      else if(action === 'staff') this.$router.push('/auth/settings/users')
      else if(action === 'fulfillment_location') this.dialog = true
    }

  },
  mounted(){
  
  },

  
}
</script>
<style lang="scss" >
  
</style>